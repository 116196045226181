import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import routes from "./pathes";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

Vue.use(Meta);

router.beforeEach((to, from, next) => {
  if (to.name === "Login") {
    if (store.getters["auth/getHash"]) {
      return router.push("/modules");
    }
  }

  next();
});

const defaultTitle = "Portal Venturus - ZNAP Technologies";
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title
      ? `${to.meta.title} - ${defaultTitle}`
      : defaultTitle;
  });
});

export default router;
